import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, computed} from 'vue';
import DataInfoUtil ,{IDataInfoDataObj} from "./dataInfoUtil";
import ToolsProviderApi from "@/utils/api/ToolsProviderApi";
export default defineComponent ({
    name: "dataInfo",
    components: {},
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDataInfoDataObj=reactive<IDataInfoDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            pageListRef:null as any,
            pageList: {
                title:'差异表',
                isShowFixCol:false,
                isShowQueryParam:false,
                // canPage: false,
                modelMethod: utils.ToolsProviderApi.buildUrl("/dataInfo/pageData")
            },
            otherParams:{
                dialogVisiable:false,
                activeStep:0,
                fullscreenLoading:false
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DataInfoUtil(proxy,dataObj,props,context);
        })
        onMounted(()=>{
            // dataObj.pageListRef.listButtons[1].disabled=true;
        })
        //---------------------------computed------------
        //下拉框初始化参数
        const importGuidParams=computed(()=> {
            return (params:any) => {
                return Object.assign({
                    ownerInst:proxy,
                    uploadParams:{
                        type:params.type,
                        saveType:0,
                        action:utils.ToolsProviderApi.buildUrl('/dataInfo/uploadFiles')
                    },
                    downloadParams:{
                        sourceName:params.sourceName,
                        downLoadFileName:params.downLoadFileName,
                        action:utils.ToolsProviderApi.buildUrl('/dataInfo/downLoadResourceFile')
                    }},params)
            };
        })

        const importDataHandler=()=>{
            dataObj.otherParams.activeStep=0;
            dataObj.otherParams.dialogVisiable=true;
        }

        const nextStep=()=>{
            switch (dataObj.otherParams.activeStep) {
                case 0:dataObj.refMap.get('importErpGuid').importDataDialogVisible=true;break;
                case 1:dataObj.refMap.get('importPlmGuid').importDataDialogVisible=true;break;
                case 2:
                    dataObj.pageListRef.queryHandler();
                    dataObj.otherParams.activeStep++;
                    break;
            }

            if(dataObj.otherParams.activeStep>2){
                dataObj.otherParams.dialogVisiable=false;
            }
        }
        //erp上传结果
        const uploadErpResult=(res:any)=>{
            dataObj.utilInst.uploadResult(res,'importErpGuid');
        }
        //plm上传结果
        const uploadPlmResult=(res:any)=>{
            dataObj.utilInst.uploadResult(res,'importPlmGuid');
        }
        return{
            ...toRefs(dataObj),importGuidParams,importDataHandler,nextStep,
            uploadErpResult,uploadPlmResult
        }
    }
});