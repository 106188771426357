import BaseBean from "@/utils/BaseBean";

export interface IDataInfoDataObj {
    utilInst:DataInfoUtil
    refMap:Map<string,any>
    pageList:any
    pageListRef:any
    otherParams:any
}
export default class DataInfoUtil extends BaseBean{
    public dataObj:IDataInfoDataObj;
    public props:any;
    public context:any;

    constructor(proxy:any,dataObj:IDataInfoDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj
        this.props=props
        this.context=context
    }

    //上传结果处理
    public uploadResult(res:any,uploadType:string):void{
        if(res.result){
            this.dataObj.otherParams.activeStep++;
            this.dataObj.refMap.get(uploadType).importDataDialogVisible=false;
        }else{
            this.utils.Tools.info({message: (res.msg?res.msg:"上传失败!")});
            if(res.errorData){
                this.dataObj.refMap.get(uploadType).refMap.get('importGuid').resultMsg=res.errorData;
                this.dataObj.refMap.get(uploadType).refMap.get('importGuid').uploadResultDrawer=true;
            }
        }
    }
}